import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {login} from '../actions/postActions.js';
import './css/form.css'
import MapContainer from './mapComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from'@fortawesome/free-regular-svg-icons'
import ScrollableAnchor from 'react-scrollable-anchor';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './translations/en.js'
import slo from './translations/slo.js'
import emailjs from '@emailjs/browser';
import gtag from '../utils/gtag.js';

counterpart.registerTranslations('slo', slo);
counterpart.registerTranslations('en', en);

counterpart.setLocale('en');


class inquiryPostForm extends Component {
constructor(props){
  super(props);
  this.formRef = React.createRef()
  this.state = {
    name1: 'Name',
    name: 'Name',
    website: 'Website',
    //password: 'Email',
    clientText: "Tell us more",
    v: 0,
    email:'Email',
    email1:'Email',
    phone: 'Phone number',
    coupon: 'Discount code',
    firstInput: 'Name',
    secondInput:'Email',
    firstInput1: 'Name',
    secondInput1:'Email',
    thirdInput: 'Phone number',
    fourthInput: 'Tell us more',
    fifthInput: 'Discount code',
    sixthInput: "Website"
    };

  this.onChange = this.onChange.bind(this);
  //this.onSubmit = this.onSubmit.bind(this);
  this.focus = this.focus.bind(this);
  this.blur = this.blur.bind(this);
  this.sendEmail = this.sendEmail.bind(this);

}

sendEmail(e){
  e.preventDefault();
  console.log("2222", this.formRef.current);
  console.log(process.env.REACT_APP_TITLE);
  emailjs
    .sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      this.formRef.current,
      process.env.REACT_APP_PUBLIC_KEY
    )
    .then(
      (result) => {
        alert('message sent successfully...');
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
};
sendOutboundInquiry(event) {
 gtag('event', 'submited_form_service_inquiry');
 }
 sendOutboundNewsletter(event) {
  gtag('event', 'submited_form_newsletter_signup');
  }
onChange(e) {
  this.setState({ [e.target.name]: e.target.value });
}
focus(e){
    if(e.target.value === this.state.firstInput || e.target.value === this.state.secondInput || e.target.value === this.state.thirdInput || e.target.value === this.state.fourthInput || e.target.value === this.state.fifthInput || e.target.value === this.state.sixthInput){
    e.target.value = '';  
    }
    if(e.target.rows){
      e.target.rows = "5";
    }
}
blur(e, inpt){
  if(!e.target.value) e.target.value = inpt;
 if(e.target.rows) e.target.rows = "1";
}
// onSubmit(e) {
//  e.preventDefault();
//  var sequence = this.state.v + 1;
//  this.setState({v: sequence});
//  if(this.state.clientText !== 'How can we help you?'){
//   var post = {
//     name: this.state.name,
//     text: this.state.clientText,
//     email: this.state.email,
//     v: this.state.v
//   }
// }
// if(this.state.clientText === 'How can we help you?') {
//  var post = {
//    name: this.state.name,
//    text: '',
//    email: this.state.email,
//    v: this.state.v
//  }}
// this.props.login(post);
// alert( "Success,\nWe will get back to your shortly");
// }

  render() {
    counterpart.setLocale('en');
    // gtag('event', 'submited_form_en');
    // gtag('event', 'submited_form_service_inquiry');
    
    return (
      <ScrollableAnchor id={'contact'}>
      <section id="form">
      <div id="interested">
      <div id="interestedTitle">
      <Translate content="inquiry.contact.title" component="h2"/>
      <form onSubmit={this.onSubmit}>
      <div id="interestedInput">
      <input type="text" name="name1" onFocus={this.focus} onBlur={ e => this.blur(e, this.state.firstInput1)} onChange={this.onChange} value={this.state.name1}/>
      <input type="text" name="email1" onFocus={ e=> this.focus(e)} onBlur={ e => this.blur(e, this.state.secondInput1)} onChange={this.onChange} value={this.state.email1}/>
      <input type="text" name="website" onFocus={ e=> this.focus(e)} onBlur={ e => this.blur(e, this.state.sixthInput)} onChange={this.onChange} value={this.state.website}/>
      <button type="submit" id="submitEnPostNewsletter" onClick={this.sendOutboundNewsletter}><Translate content="inquiry.contact.submitButton"/></button>
      </div>
      </form>
      </div>
      </div>
        <div className="inquiryFormHeader">

          {/* <h2>Ready to start? Let's create something amazing together!</h2> */}
          </div>
      <div id="input-form">
        <div className="inquiryContactInnerHeader">
        <Translate content="inquiry.contact.title1" component="h1"/><br/>
        <Translate content="inquiry.contact.title2" component="h3"/>
        <Translate id="inquiryBolder" content="inquiry.contact.title3" component="h3"/>
        </div>

      {/*  //this.onsubmit */}
        <form onSubmit={this.sendEmail} ref={this.formRef}>
          <div className="stylish">
            <input type="text" name="name" isrequired onFocus={this.focus} onBlur={ e => this.blur(e, this.state.firstInput)} onChange={this.onChange} value={this.state.name}/>
          </div>
          <div className="stylish">
            <input type="email" name="email" isrequired onFocus={ e=> this.focus(e)} onBlur={ e => this.blur(e, this.state.secondInput)} onChange={this.onChange} value={this.state.email}/>
          </div>
          {/* <div className="stylish">
            <input type="tel" name="phone" isrequired onFocus={ e=> this.focus(e)} onBlur={ e => this.blur(e, this.state.thirdInput)} onChange={this.onChange} value={this.state.phone}/>
          </div>
          <div className="stylish">
            <textarea rows = "1" type="text" name="clientText" onFocus={this.focus} onBlur={ e => this.blur(e, this.state.fourthInput)} onChange={this.onChange} value={this.state.clientText}/>
          </div> */}
          <div className="stylish">
            <input type="text" name="coupon" isrequired onFocus={ e=> this.focus(e)} onBlur={ e => this.blur(e, this.state.fifthInput)} onChange={this.onChange} value={this.state.coupon}/>
          </div>
          <br />
          <button type="submit" id="submitEnPostInquiry"onClick={this.sendOutboundInquiry}><Translate content="inquiry.contact.getQuoteButton"/></button>
        </form>
        <div id="SocialForm">
        <div className="SocialFormContent">

        <div className="SocialFormIcon">
        <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faMapMarkerAlt} size="sm"/>
        <FontAwesomeIcon icon={faCircle} size="lg"/>
        </span>
        </div>
        <div className="SocialFormText">
        <Translate content="contact.address" component="h3"/>
        <p>Mesarska cesta 26 1000, Ljubljana</p></div>
        </div>
        {/* <div className="SocialFormContent">
        <div className="SocialFormIcon">
        <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faPhoneAlt} size="sm"/>
        <FontAwesomeIcon icon={faCircle} size='lg' />
        </span>
        </div>
        <div className="SocialFormText">
        <Translate content="contact.phone" component="h3"/>
        <p>+386 51 621 161</p></div>
        </div> */}
        <div className="SocialFormContent">
        <div className="SocialFormIcon">
        <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faEnvelope} size="sm"/>
        <FontAwesomeIcon icon={faCircle} size='lg' />
        </span>
        </div>
        <div className="SocialFormText">
        <Translate content="contact.email" component="h3"/>
        <p>info@bprogramming.com</p></div>
        </div>
        </div>
        </div>
        <div className="map">
      <MapContainer />
      </div>

        </section>
        </ScrollableAnchor>
    )
  }
}
inquiryPostForm.propTypes = {
login: PropTypes.func.isRequired
};

export default connect(null, {login})(inquiryPostForm);